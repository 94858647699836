/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;    
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.partner-slider {

    li {

        a {
            display: table;
            margin: 0 auto;
        }
    }

    img {
        max-width: rem(200px) !important;
        width: auto !important;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;        
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-dots {
    display:none !important;
}

.teaserSlider .slick-dots {

    @include breakpoint(giant) {
        display:flex !important;
        position:absolute;
        bottom:0;
        left:50%;
        transform:translateX(-50%);
        z-index:2;
        overflow:hidden;

        li {
            margin:0 rem(2px);
            line-height:rem(20px);
            transition:.5s;

            button {
                border:none;
                background:rgba($light,.15);
                text-indent:rem(-9999px);
                width:rem(385px);
                height:3px;
                cursor:pointer;
                transition:.5s;
                outline:none;

                &:hover {
                    background:rgba($light,.05);
                }
            }

            &.slick-active {

                button {
                    background:rgba($light,.3);

                    &:hover {
                        background:rgba($light,.2);
                    }
                }
            }
        }
    }
}

.slick-arrow {
    display:none !important;
}

.imgSlider .slick-arrow {
    z-index:3;
    width:rem(80px);
    height:rem(40px);
    border:none;
    text-indent:-9000px;
    overflow:hidden;
    position:absolute;
    bottom:calc(50% - 20px);
    outline:none;
    cursor:pointer;
    transition:.5s;
    z-index:5;

    @include breakpoint(giant) {
        display:block !important;
    }    

    &:hover {
        opacity:.7;
    }

    &.slick-prev {
        left:-120px;
        background:image-url("prev.png") center center no-repeat;
    }

    &.slick-next {
        right:-120px;
        background:image-url("next.png") center center no-repeat;
    }
}
