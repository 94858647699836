* {
	margin: 0;
	padding: 0;
	outline:none;

	&, &:before, &:after {
		box-sizing: inherit;
	}
}

::selection {}
::-moz-selection {}

// DO NOT set font-size and line-height here!
// Adjust $base-font-size and $base-line-height in _config.scss
html {
	background: $primary;
	box-sizing: border-box;
	color: $light;
	font-family: $main-font;
	font-size: 100% * ($base-font-size / 16px);
	hyphens: manual;
	line-height: rem($base-line-height);
	font-weight:400;

	/* disable text resize in landscape. e.g. on iphone */
	text-size-adjust: none;
}

body {
	line-height: rem($base-line-height);
	overflow-x:hidden;
	opacity: 1 !important;

	// Debug mode
	&.the-customer-page #log_hider {
		display: none;
	}

	&.ie-8 #pageloader:after {
		display: none;
	}
}

iframe {
	border: none;
	width: 100%;
}

b, strong {
	font-weight:800;
}

/**
 * General Margin
 */
p, ol, dl, .margin-bottom {
	margin-bottom: rem($base-line-height);
}

/**
 * Links
 */
a {
	color:$light;
	text-decoration:underline;
	outline:none;
	word-wrap: break-word;

	&:focus, &:hover, &:active {
		text-decoration:none;
	}

	img {
		border:none;
	}

	&.pdf:before {
		@extend .fa;
		@extend .fa-file-pdf;
		margin-right: 0.5em;
	}

	&[href^=tel] {
        color:inherit;
        text-decoration:underline;
    }
}

hr, .hr {
	display: flex;
	flex-basis: 100%;
	border:none;
	clear:both;
	height:1px;
	margin:2rem 0 4rem;
	background:rgba($light,.5);

	@include breakpoint(large) {
		margin:5rem 0 7rem;
	}

	&.last:last-child {
		display:none;
	}

	.col & {
		margin:2rem 0 4rem;

		@include breakpoint(large) {
			margin:3rem 0 5rem;
		}
	}

}

ul {
	list-style: none;

	&.styled-list {
		@extend .margin-bottom;

		li {
			padding-left:rem(60px);
			margin-bottom:rem(7px);

			&:before {
				@extend .icon-default;
				@extend .icon-circle-check;
				text-indent: rem(-26px);
				color:$secondary;
			}
		}

		ul {
			margin-left:1rem;
			margin-bottom:0;
		}
	}

	&.unstyled-list {
		margin-bottom:0;

		li {
			padding-bottom:0;
			padding-left:0;

			&:before {
				content:none;
			}
		}
	}

	&.noSpace {
		margin-bottom:0;
	}
}

dl {
	
	&.styled-list {
		@include pie-clearfix;

		dt, dd {
			display: block;
			background: $dark;
			padding: rem(20px) rem(25px);
		}

		dt {
			padding-bottom: 0;
			font-weight: 700;
		}

		dd {
			padding-top: 0;
			
			&:not(:last-child) {
				margin-bottom: rem(10px);
			}
		}
	}

	&.float-list {
		@include pie-clearfix;

		dt, dd {
			display: block;
			vertical-align: top;
			float: left;
		}

		dt {
			padding-right: .5rem;
			width: 40%;
			clear: left;
		}

		dd {
			width: 60%;
		}
	}

	&.noSpace {
		margin-bottom: rem(10px);
	}
}

ol {
  list-style-type: none;
  counter-reset: item;

  li {
	padding-top: .3rem;
	padding-bottom: .3rem;
	display: table;
	counter-increment: item;

		&:before {
			content: counters(item, ".") ". ";
			display: table-cell;
			padding-right: .3em;
		}
	}

	ol {
		margin-bottom: 0;

		li {

			&:before {
				content: counters(item, ".") " ";
			}
		}
	}
}

/*
 * responsive images
 */

img {
	vertical-align: bottom;
    float: none;
    height: auto;
    max-width: 100%;
    width: 100%;

    &[src^='http://cms.'],
    &[src^='https://cms.'] {
		max-width: none;
		width: auto;
	}

    [data-rel] &, .lightbox-image & {
        margin-bottom: 0;
    }
}

/*
 * reset copyright link
 */
[href*='alpen-web.ch'],
[href*='bewertet.de'],
[href*='euroweb.at'],
[href*='euroweb.de'],
[href*='ew.de'],
[href*='geprueft.de'],
[href*='web2walk.com'],
[href*='webstyle.de'],
[href*='webstyle.com'],
[href*='wn-onlineservice.de'],
[href*='internet-media.com'],
[href*='yourrate.com'] {
    display: table;
    text-decoration: none;

    img {
        background: none;
        border-radius: 0;
        border: none;
        margin-bottom: rem(5px);
        outline: none;
        padding: 0;
        white-space: normal;
        width: auto !important;
    }
}

/**
 * Tables
 */

table {
	@extend .margin-bottom;
	display:block;
	border-collapse:collapse;
	width:100%;
	max-width:100%;

	@include breakpoint(medium) {
		display:table;
	}

	tr {
		display:block;
		padding:rem($base-gap) 0;

		@include breakpoint(medium) {
			display:table-row;
		}

		&:not(:last-child) {
			border-bottom:rem(1px) solid rgba($light,.2);
		}

		&:nth-child(2n+2) {
			background:$dark;
		}
	}

	th, td {
		padding:rem($base-gap);
		display:block;
		vertical-align:top;

		@include breakpoint(medium) {
			display:table-cell;
		}

		&:first-child {
			padding-bottom:0;

			@include breakpoint(medium) {
				padding-bottom:rem($base-gap);
			}
		}
	}
}