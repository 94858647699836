

.image {
    margin-bottom:rem($base-line-height);
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Header
.header {
    position:relative;
    width:100%;
    max-width:rem(1900px);
    margin:0 auto rem(40px);
    overflow:hidden;

    @include breakpoint(giant) {
        padding-top:rem(190px);
        margin-bottom:rem(80px);
        transition:.5s;
    }

    @include breakpoint(huge) {
        margin-bottom:rem(100px);
    }

    @include breakpoint(huge2) {
        margin-bottom:rem(120px);
    }

    body.index & {
        margin-bottom:rem(120px);

        @include breakpoint(large) {
            margin-bottom:rem(160px);
        }

        @include breakpoint(giant) {
            padding-top:rem(243px);
            margin-bottom:rem(265px);
        }

        @include breakpoint(huge) {
            padding-top:rem(349px);
        }
    }

    body.index.scroll & {
        @include breakpoint(giant) {
            padding-top:rem(190px);
        }
    }

    .topBox {

        @include breakpoint(giant) {
            position:fixed;
            top:0;
            left:0;
            right:0;
            background:$primary;
            z-index:99;
            border-bottom:1px solid rgba($light,.2);
        }

        body.index & {
            @include breakpoint(giant) {
                border-bottom:none;
            }
        }

        body.index.scroll & {
            @include breakpoint(giant) {
                border-bottom:1px solid rgba($light,.2);
            }
        }

        .top {
            display:none;
            border-bottom:1px solid rgba($light,.2);
            padding:rem(28px) 0 rem(18px);

            @include breakpoint(giant) {
                display:block;
            }

            ul.contactData {
                display:flex;
                justify-content:space-between;
                font-size:rem(14px);
                line-height:rem(24px);

                @include breakpoint(huge) {
                    font-size:rem(15px);
                    line-height:rem(25px);
                    padding-right:rem(50px);
                    text-transform:uppercase;
                }

                a {
                    text-decoration:none;
                    transition:.5s;

                    &:hover {
                        color:rgba($light,.5);
                    }
                }
            }

            ul.socials {
                display:flex;
                justify-content:flex-end;
                line-height:rem(10px);

                li {
                    &:last-child {
                        margin-left:rem(10px);
                    }
                }
            }
        }

        .naviBox {
            padding:rem(84px) 0 rem(40px);
            text-align:center;

            @include breakpoint(giant) {
                padding:rem(24px) 0 rem(24px);
                transition:.5s;
                text-align:left;
            }

            body.index & {
                @include breakpoint(giant) {
                    padding:rem(54px) 0 rem(48px);
                }

                @include breakpoint(huge) {
                    padding:rem(84px) 0 rem(78px);
                }
            }

            body.index.scroll & {
                @include breakpoint(giant) {
                    padding:rem(24px) 0 rem(24px);
                }
            }

            .branding {
                display:inline-block;
                padding:0 rem($base-gap);

                @include breakpoint(large) {
                    padding:0;
                }

                @include breakpoint(giant) {
                    max-width:rem(260px);
                    transition:.5s;
                }

                body.index & {
                    @include breakpoint(giant) {
                        max-width:rem(436px);
                    }
                }

                body.index.scroll & {
                    @include breakpoint(giant) {
                        max-width:rem(260px);
                    }
                }

                img {
                    width:auto;
                }
            }
        }
    }

    .sliderBox .slider, .sliderBox .slider .slide, .sliderBox {
        height:rem(300px);

        body.index & {
            height:rem(370px);

            @include breakpoint(medium) {
                height:rem(400px);
            }

            @include breakpoint(large) {
                height:rem(500px);
            }

            @include breakpoint(giant) {
                height:rem(635px);
            }

            @include breakpoint(huge) {
                height: rem(660px);
            }
        }
    }

    .sliderBox {
        display:none;
        position:relative;
        margin-bottom:rem(44px);

        @include breakpoint(giant) {
            display:block;
        }

        body.index & {
            display:block;
        }

        .slider {
            position:relative;
            overflow:hidden;
            width:100%;

            .slide {
                width:100%;
                position:relative;
                z-index:1;
                background: {
                    position:center;
                    repeat:no-repeat;
                    size:cover;
                };

                &.slide-1 {

                   background: {
                    image:image-url("slide/def-fliesen-kaeppeler-1.jpg");
                };

                @include breakpoint(small){
                   background: {
                    image:image-url("slide/small-fliesen-kaeppeler-1.jpg");
                }; 
            }
            @include breakpoint(large){
             background: {
                image:image-url("slide/fliesen-kaeppeler-1.jpg");
            };
        }          
    }

    body.index & {

     @for $slide from 1 through 6 {
        &.slide-#{$slide} {
            &.lazyLoaded{
                background: {
                    image:image-url("slide/def-fliesen-kaeppeler-#{$slide}.jpg");
                };
            }
        }
    }


    @include breakpoint(small){
        @for $slide from 1 through 6 {
            &.slide-#{$slide} {
                &.lazyLoaded{
                    background: {
                        image:image-url("slide/small-fliesen-kaeppeler-#{$slide}.jpg");
                    };
                }
            }
        }
    }


    @include breakpoint(large){
        @for $slide from 1 through 6 {
            &.slide-#{$slide} {
                &.lazyLoaded{
                    background: {
                        image:image-url("slide/fliesen-kaeppeler-#{$slide}.jpg");
                    };
                } 
            }
        }

    }

    &:not(.slick-initialized) {
        .slide-2, .slide-3, .slide-4, .slide-5, .slide-6 {
            display:none;
        }
    }

    &:after {
        content:'';
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background:rgba(#000,.6);
        z-index:2;

        @include breakpoint(medium) {
            background:linear-gradient(to bottom, rgba(#000,0) 40%, rgba(#000,.8) 100%);
        }
    }
}
}
}

.text {
    position:absolute;
    left:0;
    right:0;
    bottom:rem(30px);
    z-index:2;
    text-align:center;

    @include breakpoint(medium) {
        text-align:left;
    }

    @include breakpoint(giant) {
        bottom:rem(80px);
    }

    @include breakpoint(huge2) {
        bottom: rem(80px);
    }

    .claim {
        font-size:rem(20px);
        line-height:rem(30px);
        margin-bottom:rem(40px);

        @include breakpoint(medium) {
            margin-bottom:0;
            font-size:rem(25px);
            line-height:rem(35px);
            padding-right:5%;
        }

        @include breakpoint(giant) {
            font-size:rem(32px);
            line-height:rem(42px);
            padding-right:0;
        }

        @include breakpoint(huge) {
            font-size:rem(46px);
            line-height:rem(58px);
        }

        @include breakpoint(huge2) {
            font-size:rem(50px);
            line-height:rem(62px);
        }
    }

    .line {
        display:none;
        width:calc(100% + 30px);
        height:1px;
        margin:rem(10px) 0 rem(10px) -30px;
        background:$secondary;

        @include breakpoint(medium) {
            display:block;
        }

        @include breakpoint(giant) {
            width:calc(100% + 60px);
            margin:rem(30px) 0 rem(30px) -30px;
        }
    }

    .box {
        border:3px solid $secondary;
        text-align:center;
        font-size:rem(14px);
        line-height:rem(30px);
        margin:0;
        padding:rem(10px) rem($base-gap) rem(13px);
        display:inline-block;

        @include breakpoint(giant) {
            margin-left:rem(30px);
            padding:rem(10px) rem(12px) rem(13px);
        }

        .highlight {
            font-family:$display-font;
            font-size:rem(34px);
            line-height:rem(30px);
            display:block;
            color:$light;

            span {
                display:block;
            }
        }
    }
}
}

.teaserBox {
    margin-bottom:rem(60px);

    @include breakpoint(small) {
        width:calc(100% + 40px);
        margin:0 0 rem(100px) -20px;
    }

    @include breakpoint(giant) {
        margin-bottom:rem(260px);
    }

    .teaserSlider {
        overflow:hidden;
        position:relative;

        &:before, &:after {

            @include breakpoint(large) {
                content:'';
                position:absolute;
                top:0;
                bottom:0;
                width:rem(20px);
                background:$primary;
                z-index:2;
            }
        }

        &:before {
            left:0;
        }

        &:after {
            right:0;
        }

        .slide {
            position:relative;
            z-index:1;

            @include breakpoint(small) {
                margin:0 rem($base-gap);
            }

            @include breakpoint(giant) {
                padding-bottom:rem(100px);
            }                

            &:not(.slick-initialized) {
                .slide-2 {
                    display:none;
                }
            }

            .link {
                display:block;
                position:relative;
                padding-bottom:rem(15px);
                transition:.5s;

                @include breakpoint(giant) {
                    padding-bottom:rem(20px);
                }

                img {
                    margin:0 auto;
                }

                &:after {
                    content:'';
                    position:absolute;
                    z-index:1;
                    left:0;
                    bottom:0;
                    right:0;
                    top:0;
                    background:linear-gradient(to bottom, rgba($primary,0) 45%, rgba($primary,.8) 100%);
                }

                .text {
                    position:absolute;
                    z-index:2;
                    left:0;
                    bottom:0;
                    font-size:rem(20px);
                    line-height:rem(30px);
                    font-weight:800;
                    padding-left:rem(20px);
                    transition:.5s;

                    @include breakpoint(medium) {
                        font-size:rem(17px);
                        line-height:rem(27px);
                    }

                    @include breakpoint(large) {
                        font-size:rem(22px);
                        line-height:rem(32px);
                    }

                    @include breakpoint(giant) {
                        font-size:rem(26px);
                        line-height:rem(36px);
                        padding-left:rem(30px);
                    }

                    @include breakpoint(huge) {
                        font-size:rem(37px);
                        line-height:rem(47px);
                    }

                    i {
                        color:$secondary;
                        font-weight:400;
                    }
                }

                &:hover {
                    .text {
                        bottom:rem(30px);
                    }
                }
            }
        }
    }
}

.infoBox {
    text-align:center;

    @include breakpoint(medium) {
        text-align:left;
    }

    .title {
        display:block;
        font-size:rem(20px);
        line-height:rem(30px);
        font-weight:800;
        margin-bottom:rem(20px);

        @include breakpoint(medium) {
            font-size:rem(28px);
            line-height:rem(38px);
        }

        @include breakpoint(giant) {
            font-size:rem(36px);
            line-height:rem(46px);
        }

        @include breakpoint(huge) {
            font-size:rem(46px);
            line-height:rem(56px);
        }

        @include breakpoint(huge2) {
            font-size:rem(50px);
            line-height:rem(60px);
        }

        span {
            display:block;
        }
    }

    .btnBox {
        margin-bottom:rem(40px);

        @include breakpoint(medium) {
            margin-bottom:0;
        }

        br {
            @include breakpoint(tiny) {
                display:none;
            }

            @include breakpoint(medium) {
                display:block;
            }

            @include breakpoint(large) {
                display:none;
            }
        }

        a {
            display:inline-block;
            color:rgba($light,.4);
            text-decoration:none;
            font-size:rem(18px);
            line-height:rem(28px);
            font-weight:800;
            position:relative;
            transition:.5s;
            margin-bottom:rem(20px);

            @include breakpoint(tiny) {
                margin-bottom:0;
            }

            @include breakpoint(giant) {
                font-size:rem(24px);
                line-height:rem(60px);
            }

            &:first-child {
                @include breakpoint(tiny) {
                    margin-right:rem(20px);
                }

                @include breakpoint(medium) {
                    margin-right:0;
                }

                @include breakpoint(large) {
                    margin-right:rem(20px);
                }
            }

            &:after {
                content:'';
                display:block;
                position:absolute;
                left:0;
                bottom:0;
                background:rgba($light,.4);
                width:100%;
                height:1px;
                transition:.5s;
            }

            &:hover {
                color:rgba($light,.6);

                &:after {
                    width:0;
                }
            }

            &.color {
                color:$secondary;

                &:after {
                    background:$secondary;
                }

                &:hover {
                    color:lighten($secondary,25%);

                    &:after {
                    }
                }
            }
        }
    }

    .logo {
        display:none;

        @include breakpoint(giant) {
            display:inline-block;
        }

        img {
            max-width:70%;

            @include breakpoint(huge) {
                max-width:100%;
            }
        }
    }

    .logoBox {
        display:flex;
        justify-content:space-between;

        @include breakpoint(giant) {
            width:calc(100% + 55px);
            margin-left:-55px;
        }

        a {
            transition:.5s;

            &:last-child {
                margin-left:rem(20px);
            }

            &:hover {
                opacity:.8;
            }
        }
    }
}
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


// Content
.content {
    font-size:rem(15px);
    line-height:rem(25px);
    max-width:rem(2000px);
    margin:0 auto;

    @include breakpoint(small) {
        font-size:rem(16px);
        line-height:rem(26px);
    }

    @include breakpoint(medium) {
        font-size:rem(17px);
        line-height:rem(27px);
    }

    @include breakpoint(large) {
        font-size:rem(18px);
        line-height:rem(28px);
    }

    @include breakpoint(giant) {
        font-size:rem(19px);
        line-height:rem(30px);
    }

    @include breakpoint(huge) {
        font-size:rem($base-font-size);
        line-height:rem($base-line-height);
    }

    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        text-rendering:optimizeLegibility;
        font-weight:800;

        span {
            display:block;
        }
    }

    h1, .h1 {
        font-size:rem(20px);
        line-height:rem(30px);
        margin-bottom:rem(20px);
        position:relative;
        padding:rem(4px) 0;
        margin-left:rem(8px);

        @include breakpoint(large) {
            font-size:rem(24px);
            line-height:rem(34px);
            margin-bottom:rem(40px);
            padding:rem(8px) 0;
        }

        @include breakpoint(giant) {
            font-size:rem(29px);
            line-height:rem(39px);
            margin-left:rem(15px);
        }

        @include breakpoint(huge) {
            font-size:rem(36px);
            line-height:rem(46px);
        }

        @include breakpoint(huge2) {
            font-size:rem(42px);
            line-height:rem(52px);
            margin-left:0;
        }

        @include breakpoint(huge3) {
            font-size:rem(46px);
            line-height:rem(56px);
        }

        body.index & {
            margin-left:0;
        }

        &:before {
            content:'';
            position:absolute;
            left:-8px;
            top:0;
            bottom:0;
            width:rem(20px);
            background:$secondary;
            z-index:-1;

            @include breakpoint(giant) {
                width:rem(40px);
                left:-15px;
            }
        }
    }

    h2, .h2 {
        font-size:rem(19px);
        line-height:rem(29px);
        margin-bottom:rem(15px);

        @include breakpoint(large) {
            font-size:rem(22px);
            line-height:rem(22px);
            margin-bottom:rem(20px);
        }

        @include breakpoint(giant) {
            font-size:rem(26px);
            line-height:rem(36px);
        }

        @include breakpoint(huge) {
            font-size:rem(30px);
            line-height:rem(40px);
        }

        a {
            color:$secondary;
            text-decoration:none;
            transition:.5s;

            &:hover {
                color:darken($secondary,15%);
            }
        }
    }

    h3, .h3 {
        font-size:rem(18px);
        line-height:rem(28px);
        margin-bottom:rem(10px);
        color:$secondary;

        @include breakpoint(large) {
            font-size:rem(20px);
            line-height:rem(30px);
        }

        @include breakpoint(giant) {
            font-size:rem(25px);
            line-height:rem(35px);
        }
    }

    h4, .h4 {
        font-size:rem(16px);
        line-height:rem(26px);
        margin-bottom:rem(10px);

        @include breakpoint(large) {
            font-size:rem(23px);
            line-height:rem(33px);
            margin-bottom:rem(15px);
        }
    }

    h5, .h5, h6, .h6 {
        font-size:rem(16px);
        line-height:rem(26px);
        margin-bottom:rem(10px);

        @include breakpoint(large) {
            font-size:rem(22px);
            line-height:rem(32px);
        }
    }

    ul, dl {
        @extend .styled-list;
    }

    .google-maps {
        margin-bottom:rem($base-line-height);

        iframe {
            border:none;
            width:100%;
            height:rem(400px);

            @include breakpoint(large) {
                height:rem(600px);
            }
        }

        body.nojs & {
            display:none;
        }
    }

    .video01 {



        &.lazyLoaded{

          background: {
            image:image-url("fliesen-kaeppeler-2-def.jpg");
            position:center center;
            repeat:no-repeat;
            size:cover;
        };

        @include breakpoint(small){
         background: {
            image:image-url("fliesen-kaeppeler-2-small.jpg");
            position:center center;
            repeat:no-repeat;
            size:cover;
        };
    }



    @include breakpoint(large){
        background: {
            image:image-url("fliesen-kaeppeler-2.jpg");
            position:center center;
            repeat:no-repeat;
            size:cover;
        };
    }
}
display:flex;
width:100%;
justify-content:center;
align-items:center;
text-decoration:none;
transition:.5s;
color:$light;
height:rem(200px);
position:relative;
margin-bottom: rem($base-line-height);

@include breakpoint(small) {
    height:rem(260px);
}

@include breakpoint(medium) {
    height:rem(360px);
}

@include breakpoint(large) {
    height:rem(660px);
}

&:after {
    content:'';
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    background:rgba($primary,.3);
    z-index:1;
    display:block;
    transition:.5s;
}

i {
    font-size:rem(50px);
    display:block;
    position:relative;
    z-index:2;
    color:$secondary;

    @include breakpoint(small) {
        font-size:rem(70px);
    }

    @include breakpoint(large) {
        font-size:rem(100px);
    }

    @include breakpoint(giant) {
        font-size:rem(102px);
    }
}

&:hover {

    @include breakpoint(large) {
        &:after {
            background:rgba($primary,.5);
        }
    }
}
}

.imgSliderContainer {
    margin-bottom:rem(80px);
    position:relative;

    @include breakpoint(giant) {
        margin-bottom:rem(330px);
    }

    &:after {
        content:'';
        display:block;
        width:1px;
        height:rem(50px);
        background:$secondary;
        position:absolute;
        left:50%;
        bottom:-30px;

        @include breakpoint(giant) {
            height:rem(210px);
            bottom:-155px;
        }
    }

    .title {
        display:block;
        text-align:center;
        font-size:rem(20px);
        line-height:rem(30px);
        color:rgba($light,.4);
        font-weight:800;
        margin-bottom:rem(10px);

        @include breakpoint(medium) {
            font-size:rem(50px);
            line-height:rem(60px);
            margin-bottom:-12px;
            color:rgba($light,.25);
        }

        @include breakpoint(large) {
            font-size:rem(60px);
            line-height:rem(70px);
        }

        @include breakpoint(giant) {
            font-size:rem(80px);
            line-height:rem(90px);
            margin-bottom:-20px;
            color:rgba($light,.11);
        }

        @include breakpoint(huge) {
            font-size:rem(110px);
            line-height:rem(120px);
        }
    }

    .imgSliderBox {
        overflow:hidden;

        @include breakpoint(huge) {
            height:rem(257px);
        }

        .imgSlider {
            position:relative;

            &:before, &:after {

                @include breakpoint(large) {
                    content:'';
                    position:absolute;
                    top:0;
                    bottom:0;
                    width:rem(20px);
                    background:$primary;
                    z-index:2;
                }
            }

            &:before {
                left:0;
            }

            &:after {
                right:0;
            }

            .slide {
                position:relative;
                background:$primary;

                @include breakpoint(small) {
                    margin:0 rem($base-gap);
                }

                &:not(.slick-initialized) {
                    .slide-2 {
                        display:none;
                    }
                }

                img {
                    margin:0 auto;
                    opacity:.3;
                    transition:.5s;

                    &:hover {
                        opacity:.6;
                    }
                }

                &.slick-center {
                    img {
                        opacity:1;
                    }
                }
            }
        }
    }
}

.row.spaceBottom {
    margin-bottom:rem(60px);

    @include breakpoint(giant) {
        margin-bottom:rem(260px);
    }
}

.spaceBox {
    padding:0 rem($base-gap);

    @include breakpoint(huge) {
        padding:0 10%;
    }
}

.overlayBox, .overlayBox2 {
    position:relative;
    z-index:4;

    @include breakpoint(medium) {
        width:125%;
    }
}

.overlayBox {

    @include breakpoint(giant) {
        padding-left:14%;
    }
}

.contentBoxContainer {
    display:flex;
}

.contentBox {
    background:$color3;
    padding:rem(33px) rem(33px) rem(1px);
    margin-bottom: rem($base-line-height);
    width:100%;

    @include breakpoint(giant) {
        padding:rem(43px) rem(43px) rem(11px);
    }

    a.imgLink {
        transition:.5s;
        opacity:1;

        &:hover {
            opacity:.6;
        }
    }

    p.title {
        font-weight:700;
        margin-bottom:rem(10px);

        @include breakpoint(giant) {
            margin-bottom:rem(20px);
        }
    }

    .extLink {
        display:inline-block;
        margin-bottom:rem($base-line-height);
        padding-left:rem(30px);
        position:relative;
        color:$secondary;
        text-decoration:none;
        transition:.5s;

        &:hover {
            color:$light;
        }

        &:before {
            font-family:$icon-font2;
            content:'\ea2d';
            position:absolute;
            top:0;
            left:0;
        }
    }

    .imageBox {

        img {
            transition:.5s;
            opacity:1;

            &:hover {
                opacity:.7;
            }
        }
    }
}

.gradientImg {
    margin-bottom:rem($base-line-height);

    @include breakpoint(medium) {
        position:relative;
        display:inline-block;

        &:after {
            content:'';
            position:absolute;
            top:-1px;
            left:0;
            right:0;
            bottom:-1px;
            background:linear-gradient(to left, rgba(#000,0) 45%, rgba(#000,.9) 100%);
            z-index:1;
        }

        &.big {
            width:calc(100% + 18px);
            margin-left:-18px;

            @include breakpoint(large) {
                width:calc(100% + 36px);
                margin-left:-36px;
            }
        }
    }
}
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


// Footer
.footer {
    padding-top:rem(60px);
    max-width:rem(1900px);
    margin:0 auto;

    @include breakpoint(large) {
        padding-top:rem(140px);
    }

    @include breakpoint(giant) {
        padding-top:rem(240px);
    }

    .contactData {
        background:$secondary;
        font-size:rem(18px);
        line-height:rem(28px);
        font-weight:800;
        position:relative;
        padding:rem(50px) 0;
        text-align:center;

        @include breakpoint(medium) {
            text-align:left;
        }

        @include breakpoint(medium) {
            background:$color3;
        }

        @include breakpoint(large) {
            font-size:rem(24px);
            line-height:rem(34px);
            padding:rem(100px) 0;
        }

        @include breakpoint(giant) {
            font-size:rem(28px);
            line-height:rem(40px);
            padding:rem(120px) 0;
        }

        @include breakpoint(huge) {
            font-size:rem(30px);
            line-height:rem(43px);
        }

        &:before {
            content:'';
            position:absolute;
            left:0;
            top:0;
            bottom:0;
            width:66%;
            background:$secondary;

            @include breakpoint(large) {
                width:60%;
            }
        }

        address {
            font-style:normal;
            margin-bottom:rem(43px);
        }

        .list {

            @include breakpoint(giant) {
                position:relative;
                padding-bottom:rem(55px);
                margin-bottom:rem(44px);
            }

            &:after {
                @include breakpoint(giant) {
                    content:'';
                    position:absolute;
                    left:0;
                    bottom:0;
                    width:100px;
                    height:3px;
                    background:$light;
                }
            }
        }

        a {
            text-decoration:none;
            transition:.5s;

            &:hover {
                color:rgba($light,.5);
            }
        }
    }

    .imageBox {
        margin-bottom:rem(60px);

        @include breakpoint(giant) {
            margin-bottom:rem(140px);
        }

        .image1 {
            margin-top:rem(80px);

            @include breakpoint(giant) {
                margin-top:rem(160px);
            }
        }

        .image2 {
            margin-top:0;

            @include breakpoint(medium) {
                margin-top:-180px;
            }

            @include breakpoint(large) {
                margin-top:-260px;
            }

            @include breakpoint(giant) {
                margin-top:-430px;
            }

            @include breakpoint(huge) {
                margin-top:-520px;
            }
        }
    }

    .maps, .maps iframe {
        height:rem(400px);

        @include breakpoint(large) {
            height:rem(515px);
        }
    }

    .maps {
        overflow:hidden;

        iframe {
            border:none;
            width:100%;
        }

        // body.nojs & {
        //     display:none;
        // }
    }
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


.VideoBoxContent {
    text-align:center;
    margin-bottom:0;
    font-family:$main-font;
    font-weight:300;
    @extend .content;
    background:none !important;
    padding:0 !important;

    p {
        margin-bottom:0;
    }
}



.btn-video {cursor: pointer;
    z-index: 11;}

    .popUpContainer {

        body:not(.cmsBackend) & {
            top: 0;
            left: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            overflow: hidden;
            transition: opacity .3s, visibility .3s;
            position: fixed;
            visibility: visible;
            opacity: 1;
            z-index: 1000000;

            .popUpContent {
                width: 100%;
        //background: $light;
        max-height: 90vh;
        max-width: calc(100% - #{rem(110px)}); // for the close label
        overflow: hidden;
        overflow-y: auto;
        height: 100%;

        .videoContainer{
            height: 100%;

            iframe{
                height: 100%;
            }
        }

        @include breakpoint(giant) {
            max-width: 75%!important;
        }
 
        // @include breakpoint(full) {
        //     max-width: 65%!important;
        // }
    }
}

label {
    display:none;

    body:not(.cmsBackend) & {
        background: rgba(black, 0.8);
        display: flex;
        height: 100%;
        justify-content: flex-end;
        // padding: rem($baseGap);
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: -1;
        height: 100%;

        i {
            cursor: pointer;
            display: block;
            height: rem(35px);
            width: rem(35px);
            position: relative;
            transition: background .3s;

            &:hover {
                background: rgba(black, 0.8);

                &:before, &:after {
                    background: white;
                }
            }

            &:before, &:after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                width: rem(30px);
                height: rem(2px);
                background: #ddd;
            }

            &:before {
                transform: translate(-50%,-50%) rotate(45deg);
            }

            &:after {
                transform: translate(-50%,-50%) rotate(-45deg);
            }
        }
    }
}
}

input[id^="popUpToggled"] {
    display: none;
    &:not(:checked) {

        + {

            .popUpContainer {
                position: relative;
                opacity: 0;
                visibility: hidden;
                z-index: -1000000;
                transform:scale(0);
                pointer-events: none;
                width: 0;
                height: 0;
                height: 100%;
            }
        }
    }
}