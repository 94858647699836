
$menu-bar:60px;

.page-navi {
    font-size:rem(16px);
    line-height:rem(26px);
    
    @include breakpoint(giant, max) {
        background: {
            image:linear-gradient(to bottom, rgba($dark,.85) 0,rgba($dark,.85) 100%), image-url("fliesen-kaeppeler-1-small.jpg");
            position:center;
            repeat:no-repeat;
            size:cover;
        };
        overflow-y:auto;
        position:fixed;
        top:rem($menu-bar);
        right:0;
        transform:translateX(100%);
        // transition:.5s;
        width:map-get($breakpoints, tiny) * .8;
        z-index:1001;
        bottom:0;
        max-width:100%;
        padding:0 rem(10px) rem(20px);
    }
    @include breakpoint(large){
        transition:.5s;
    }
}

.navi-main {
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:100%;
    padding:rem(40px) 0;

    @include breakpoint(giant) {
        padding:0;
        flex-direction:row;
        justify-content:space-between;
        align-items:center;
    }

    &.mobile {
        display:flex;

        @include breakpoint(giant) {
            display:none;
        }
    }

    &.desktop {
        display:none;

        @include breakpoint(giant) {
            display:flex;
        }
    }

    li {
        transition:.5s;

        @include breakpoint(giant) {
            position:relative;
            display:flex;
            align-items:center;
            font-size:rem(17px);
            line-height:rem(27px);
        }

        @include breakpoint(huge) {
            text-transform:uppercase;
            font-weight:800;
        }

        @include breakpoint(huge2) {
            font-size:rem(19px);
            line-height:rem(29px);
        }

        &:last-child {
            @include breakpoint(giant) {
                font-weight:400;
            }
        }

        a {
            text-decoration:none;
            color:$light;
            display:block;
            padding:rem(8px) rem($base-gap);
            transition:.5s;

            @include breakpoint(giant) {
                padding:0;
            }
        }

        &.hasSub > a {

            @include breakpoint(giant) {
                &:after {
                    font-family:$icon-font2;
                    content:'\ea1d';
                    color:$secondary;
                    font-weight:400;
                }
            }
        }

        .sub {

            @include breakpoint(giant) {
                width:auto;
                min-width:100%;
                position:absolute;
                top:200%;
                left:0;
                opacity:0;
                visibility:hidden;
                border:none;
                transition:.5s;
                z-index:999;
                white-space:nowrap;
                display:block;
                text-align:left;
                background:$secondary;
                padding:rem(2px) 0
            }

            li {
                padding:0 0 0 rem(10px);
                
                @include breakpoint(giant) {
                    z-index:2;
                    position:relative;
                    transition:.5s;
                    padding:0 rem($base-gap);
                    font-weight:400;
                    text-transform:none;
                    border-bottom:1px solid rgba($light,.4);
                }

                &:last-of-type {
                    border-bottom:none;
                }

                a {
                    padding:rem(5px) rem($base-gap);

                    @include breakpoint(giant) {
                        font-size:rem(16px);
                        line-height:rem(22px);
                        padding:rem(12px) rem(5px);
                        position:relative;  
                        width:100%;
                    }

                    &:before {
                        content:'» ';
                    }
                }

                &.active > a {
                    
                    @include breakpoint(giant) {
                        color:$primary;

                        &:hover {
                            color:rgba($primary,.6);
                        }
                    }
                }

                &:hover > a {
                    @include breakpoint(giant) {
                        color:rgba($light,.7);
                    }
                }
            }
        }

        &.hover, &:hover {

            > ul {
                top:100%;
                opacity:1;
                visibility:visible;
            }
        }
        
        &:hover > a {

            @include breakpoint(giant) {
                color:rgba($light,.5);
            }
        }
        
        &.active > a, > a.active {
            color:$secondary;
            font-weight:800;

            &:hover {
                @include breakpoint(giant) {
                    color:rgba($secondary,.6);
                }
            }
        }
    }
}

.contactBox {
    color:$light;
    margin:0 rem($base-gap);
    padding:rem(40px) 0;
    border-top:1px solid rgba($light,.3);

    @include breakpoint(giant) {
        display:none;
    }

    li:nth-child(2) {
        padding-bottom:rem($base-gap);
    }

    a {
        color:$light;
        transition:.5s;

        &:hover {
            color:rgba($light,.4);
        }
    }

    .icon-default {
        font-family:$main-font;
        text-decoration:none;

        &:before {
            font-family:$icon-font2;
            padding-right:rem(10px);
        }
    }

    ul.socials {
        display:flex;
        justify-content:flex-start;
        padding-top:rem(30px);

        li {
            margin-right:rem(10px);
        }
    }
}

.mobile-bar {
    height:rem(50px);
    display:flex;
    justify-content:flex-end;
    align-items:center;
    position:fixed;
    right:calc(50% - 54px);
    top:rem(8px);
    z-index:1000;
    // transition:.5s;
    background:$secondary;
    color:$light;
    font-size:rem(16px);
    line-height:rem(16px);
    padding:2px rem(10px) 0;
    border:1px solid rgba($light,.5);

    li {

        &:first-child {
            padding:0 rem(10px);

            &:after {
                color:$light;
                content:'|';
                padding:0 0 0 rem(14px);
            }
        }

        &:last-child {
            padding:0 rem(12px) 0 rem(7px);
        }

        label[for="navi-toggled"], a {
            text-decoration:none;
            color:$light;
            display:inline-block;
        }

        label[for="navi-toggled"] {
            @extend .fa-bars;

            &:before {
                @extend .fa;
            }

            &:after {
                content:'';
                background:rgba($dark,.8);
                position:fixed;
                top:rem($menu-bar);
                bottom:0;
                left:0;
                opacity:0;
                pointer-events:none;
                transition:opacity .5s, visibility .5s;
                visibility:hidden;
                width:100%;
                z-index:-1;
            }
        }

        .phoneShow {
            display:inline-block;
            opacity:0;
            width:0;
            height:0;
            transition:.5s;
            display:none;
        }
    }

    @include breakpoint(giant) {
        display:none;
    }
}

#navi-toggled {
    display: none;

    &:checked {

        ~ * label[for="navi-toggled"] {
            @extend .fa-window-close;

            &:after {
                opacity:1;
                pointer-events:auto;
                visibility:visible;
            }
        }

        ~ * .page-navi,
        ~ .page-navi {
            transform:translateX(0);
        }

        ~ * .mobile-bar,
        ~ .mobile-bar {
            top:0;
            right:0;
            left:0;
            background:$primary;
            color:$light;
            border-radius:0;
            height:rem($menu-bar);
            justify-content:center;
            padding:0 0 0 calc(100% - 432px);

            .phoneShow {
                opacity:1;
                width:auto;
                height:auto;
                display:inline-block;
            }
        }
    }
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


.navi-add {
    display:none;

    @include breakpoint(giant) {
        display:block;
    }

    li {
        font-size:rem(20px);
        line-height:rem(32px);
        font-weight:800;

        a {
            text-decoration:none;
            transition:.5s;
            display:inline-block;
            color:$light;

            &.active {
                color:$primary;

                &:hover {
                    color:rgba($primary,.6);
                }
            }
        }
    }
}

